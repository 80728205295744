<template>

	<div class="zui-w-100 right  zui-h-100">
		<ul v-if="detail != {}" class="contentRight-r">
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label1')}}</span></div><span class="li_r">{{detail.name}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label2')}}</span></div><span
					class="li_r">{{detail.company}}</span>
			</li>
			<li>
				<div class="li_l" ><span>{{$t('industryCustomers.label3')}}</span></div><span
					class="li_r">{{detail.mainBusiness}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label4')}}</span></div><span class="li_r">{{detail.city}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label5')}}</span></div><span
					class="li_r">{{detail.airplaneStatus}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label6')}}</span></div><span
					class="li_r">{{detail.model}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label7')}}</span></div><span
					class="li_r">{{detail.planeNo}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label15')}}</span></div><span
					class="li_r">{{detail.hourage}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label8')}}</span></div><span
					class="li_r">{{detail.trustee}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label9')}}</span></div><span
					class="li_r">{{detail.satisfaction}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label10')}}</span></div><span
					class="li_r">{{detail.expirationTime}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label11')}}</span></div><span
					class="li_r">{{detail.financingBank}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label12')}}</span></div><span
					class="li_r">{{detail.workingStage}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label13')}}</span></div><span
					class="li_r">{{detail.recentPoint_dictText}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label14')}}</span></div><span
					class="li_r">{{detail.progress}}</span>
			</li>
			
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label16')}}</span></div><span
					class="li_r">{{detail.principal}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label17')}}</span></div><span
					class="li_r">{{detail.externalPerson}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label18')}}</span></div><span
					class="li_r">{{detail.mainPerson}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label19')}}</span></div><span
					class="li_r">{{detail.financialProducts}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label20')}}</span></div><span class="li_r">{{detail.fly}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label21')}}</span></div><span
					class="li_r">{{detail.loans}}</span>
			</li>
			<li>
				<div class="li_l"><span>{{$t('industryCustomers.label22')}}</span></div><span
					class="li_r">{{detail.remark}}</span>
			</li>
		</ul>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		props: [
			'detail'
		],
		data() {
			return {

			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin']),
		},
		beforeMount: function() {
			// console.log(this.detail);
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.right {
		.contentRight-r {
			flex: 1;
			list-style: none;

			li {
				width: 100%;
				color: black;
				margin-bottom: 10px;
				min-height: 30px;
				display: flex;
				flex-direction: row;

				.li_l {
					width: 20%;
					text-align: right;
					margin-right: 10px;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: flex-end;

					span {
						color: #A5A5A5;
						border-bottom: 1px solid #A5A5A5;
						font-size: 16px;
						font-weight: 400;
						padding: 5px 0;
					}
				}

				.li_r {
					flex: 1;
					padding: 5px 0;
					color: black;
					font-size: 16px;
					font-weight: 400;
					line-height: 25px;
					flex-direction: row;
					align-items: flex-start;
					justify-content: flex-end;
				}
			}
		}
	}
@media screen and (max-width: 960px) {
	/deep/ .li_l {
		width: 40% !important;
	}
	/deep/ .li_r {
		width: calc(60% - 10px) !important;
		display: block;
		word-break: break-all;
	}
}
	@media only screen and (max-width: 960px) {
		/deep/ li {
			// flex-direction: column !important;
			// width: 30% !important;
			
			/deep/ .li_l, {
				
				span {
					font-size: 10px !important;
					font-weight: 400;
				}
			}
		
		
			/deep/ .li_r {
				
				font-size: 10px !important;
				font-weight: 400;
			}
		}
	}
</style>
