<template>
	<div class="zui-flex-column zui-align-center zui-w-100 zui-h-100">
		<div class="zui-flex-row zui-center-center" style="margin-bottom: 25px;" >
			<div class="btn" :class="language=='en' ? 'width_auto' : '' " @click="goForm">{{$t('industryCustomers.addBtn')}}</div>
		</div>
		<div class="item" v-for="(item,index) in listArr" :key="index">
			<div class="itemL">
				<div><span class="spanC">{{$t('industryCustomers.time')}}：</span>
					<div>{{item.recordTime}}</div>
				</div>
				<div><span class="spanC">{{$t('industryCustomers.work')}}：</span>
					<div>{{item.recordAddress}}</div>
				</div>
				<div><span class="spanC">{{$t('industryCustomers.join')}}：</span>
					<div>{{item.recordParticipants}}</div>
				</div>
				<div><span class="spanC">{{$t('industryCustomers.record')}}：</span>
					<div>{{item.recordContent}}</div>
				</div>
			</div>
			<div class="itemR" v-if="item.recordImgs">
				<el-image v-if="item.recordImgs" class="img" fit="contain" :src="$apiUrl +'/'+ item.recordImgs"></el-image>
			</div>
		</div>
		<div class="moreBox zui-w-100 zui-m-b-20">
			<div class="more-load" v-if="isMoreLoad" @click="scrollLoadMore">
				<span v-if="loadingImg">{{$t('common.moreText')[1]}}</span>
				<span v-else>{{$t('common.moreText')[0]}}</span>
			</div>
			<div class="more-load" v-if="loadLastText">{{$t('common.moreText')[2]}}</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import {
		getQueryAirCustomerRecordByMainIdAndFront
	} from '../../api/api';
	export default {
		props: [
			'detail'
		],
		data() {
			return {
				listArr: [],
				page:1,
				limit:5
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin','language'])
		},
		beforeMount: function() {
			// console.log(this.detail);
			this.getData()
		},
		methods: {
			//加载更加
			scrollLoadMore() {
				// 防止多次加载
				if (this.loadingImg) {
					return;
				}
				//开启加载动画
				this.loadingImg = true;
				//调取 API
				this.getData();
			},
			getData() {
				let queryData = {};
				queryData.userId = this.userInfo.id
				queryData.id = this.detail.id
				queryData.pageNo = this.page
				queryData.pageSize = this.limit
				getQueryAirCustomerRecordByMainIdAndFront(queryData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						//分页 - S
						//判断是否是第一页
						this.totals = res.result.total; //总数据 
						if (this.page >= 2) {
							//追加数据
							this.listArr = this.listArr.concat(res.result.records)
						} else {
							this.listArr = res.result.records
						}
					
						if (this.totals - this.page * this.limit > 0) {
							this.isMoreLoad = true; //显示 加载更多 按钮
						} else {
							this.isMoreLoad = false; //不再显示 加载更多 按钮
							this.loadLastText = true; //显示 没有更多数据了 按钮
						}
						//分页++
						this.page++;
						// 加载完成后停止加载动画
						this.loadingImg = false;
						//分页 - e
					
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
						});
						// console.log(res.message);
					}
				});
			},
			goForm() {
				this.$emit('addForm')
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.hahaha{
		z-index: 3999;
	}
	.width_auto{
		width: auto !important;
	}
	.item {
		padding: 10px;
		background-color: #F6F5F5;
		border-radius: 8px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// align-items: center;
		width: 100%;
		box-sizing: border-box;
		height: 100%;

		.itemL {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
			padding-right: 10px;
			flex: 1;
			// width: 70%;
			div {
				display: flex;
				margin-bottom: 10px;

				span {
					display: inline-block;
					width: 120px;
					font-size: 14px;
					color: #000000;
					text-align: right;
				}

				div {
					flex: 1;
					font-size: 14px;
					color: #000000;
				}
			}

		}

		.itemR {
			// width: 30%;
			.img {
				width: 160px;
				height: 180px;
				// border: 1px solid #000000;
				background-color: white;
				position: relative;
			}
		}
	}

	.btn {
		background-color: #000000;
	}

	.btn:hover {
		color: #edb232 !important;
		// background-color: #edb232;
	}

	@media only screen and (max-width: 960px) {
		/deep/ .spanC {
			width: 78px !important;
			font-size: 12px !important;
		}
		/deep/ .itemR {
			.img {
				width: 80px !important;
				height: 100px !important;
			}
		}
		/deep/ .btn{
			width: auto !important;
			line-height: 0.6rem;
			font-size: 12px;
			padding:5px 10px !important;

		}
		/deep/ .item{
			border-radius: inherit;
			margin-left: 5px;
			width: calc(100% - 10px);
		}
		/deep/ .itemL{
			>div{
				// display: flex;
				div{
					flex-basis: 70%;
					// word-wrap: wrap;
					word-break: break-all;
				}
			}
		}
	}
</style>
