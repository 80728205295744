<template>
	<div class="zui-flex-column zui-align-center zui-w-100 zui-h-100">
		<div class="item">
			<div class="itemL">
				<el-form size="mini"  label-position="top" ref="form" :model="form" label-width="120px">
					<el-form-item  class="spanC"  :label="$t('industryCustomers.time')">
						<el-date-picker type="date" placeholder="选择日期" v-model="form.recordTime" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item  class="spanC"  :label="$t('industryCustomers.work')">
						<el-input v-model="form.recordAddress"></el-input>
					</el-form-item>
					<el-form-item  class="spanC"  :label="$t('industryCustomers.join')">
						<el-input v-model="form.recordParticipants"></el-input>
					</el-form-item>
					<el-form-item  class="spanC"  :label="$t('industryCustomers.record')">
						<el-input type="textarea" v-model="form.recordContent"></el-input>
					</el-form-item>
					<el-form-item  class="spanC"  :label="$t('industryCustomers.file')">
						<el-upload
							ref="upload"
							:action="$apiUrl + '/sys/common/upload'"
							:data="{ biz: 'temp',isup:1 }"
							list-type="fileList"
							:on-success="uploadFileSuccess"
							:on-remove="handleRemove"
							:file-list="userFile"
						>
						<el-button class="jinBtn" style="border-width: 0;" size="mini" type="primary">{{$t('industryCustomers.upload')}}</el-button>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
			<div class="itemR">
				<el-upload class="avatar-uploader" :action="$apiUrl + '/sys/common/upload'" :data="{ biz: 'temp' }"
					:show-file-list="false" :on-success="uploadImgSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="imageUrl" :src="imageUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<p class="zui-text-muted zui-text-center zui-font-xs" >{{$t('industryCustomers.desc')}}</p>
			</div>
		</div>
		<div class="zui-flex-row zui-center-center">
			<div @click="submit" class="btn">{{$t('common.submit')}}</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import { airCustomerAdd } from '../../api/api';
	export default {
		props: [
			'detail'
		],
		data() {
			return {
				imageUrl: '',
				form:{
					fkAirCustomerId:'',
					recordAddress:'',
					recordContent:'',
					recordParticipants:'',
					recordImgs:'',
					recordTime:new Date(),
					userFile:''
				},
				userFile:[],
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin'])
		},
		beforeMount: function() {
			// console.log(this.detail);
			this.form.fkAirCustomerId = this.detail.id
		},
		methods: {
			submit: function() {
				if(this.isLogin == false){
					this.$message({
						message: this.$t('pleaseLogin'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.$router.push({name:'login'});
						}
					});
					
					return;
				}
			
				//定义表单规则
				var rule = [
					{name:"recordAddress", checkType : "notnull", checkRule:"", errorMsg:this.$t('common.formMsg.mesg1')},
					{name:"recordContent", checkType : "notnull", checkRule:",", errorMsg:this.$t('common.formMsg.mesg1')},
					{name:"recordParticipants", checkType : "notnull", checkRule:"", errorMsg:this.$t('common.formMsg.mesg1')},
					
				];
			if(!this.$zuiChecker.check(this.form, rule)){
				this.$message({message: this.$zuiChecker.error,showClose: true,type: 'error',offset: 20,duration: 2000,customClass:'hahaha'});
				return;
			}
				// console.log(type);
				airCustomerAdd(this.form).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.$emit('submit')
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
						});
						// console.log(res.message);
					}
				});
			},
			uploadImgSuccess(response, file) {
				if (response.code == 0) {
					this.imageUrl = URL.createObjectURL(file.raw);
					this.form.recordImgs = response.message
				}
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				//   this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					// this.$message.error('上传头像图片大小不能超过 2MB!');
					this.$message({
						message:'上传头像图片大小不能超过 2MB!',
						type:'error',
						customClass:'hahaha',
						duration: 2000,
					})
				}
				return isLt2M;
				// return isJPG && isLt2M;
			},
			uploadFileSuccess(response,fileList) {
				// console.log(response, fileList);
				if (response.code == 0) {
					this.userFile.push(fileList);
					if (this.userFile.length > 1) {
						let userFile = this.form.userFile.split(',')
						userFile.push(response.message)
						this.form.userFile = userFile.join(',');
					}else{
						this.form.userFile = response.message;
					}
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.userFile = fileList;
				let userFile = [];
				this.$zui.each(fileList,function(k,item){
					userFile.push(item.response.message)
				})
				this.form.userFile = userFile.join(',');
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">

	.item {
		padding: 10px;
		background-color: #F6F5F5;
		border-radius: 8px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// align-items: center;
		width: 100%;
		box-sizing: border-box;
		height: 100%;

		.itemL {
			flex:1;
			padding-right: 10px;
		}

		.itemR {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			/deep/ .avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
				background-color: white;
			}

			.avatar-uploader .el-upload:hover {
				border-color: #409EFF;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 178px;
				height: 178px;
				line-height: 178px;
				text-align: center;
			}

			.avatar {
				width: 178px;
				height: 178px;
				display: block;
			}
		}
	}

	.btn {
		background-color: #000000;
	}
	.btn:hover {
		color: #edb232 !important;
		// background-color: #edb232;
	}
	@media only screen and (max-width: 960px) {
		/deep/ .item {
			flex-direction: column;
			border-radius: inherit;
			margin-left: 5px;
			width: calc(100% - 10px);
		}
		
	}
</style>
