<template>
	<!-- flex_column -->
	<div class="industry_page">
		<!-- 按钮搜索区域 -->
		<hangar :isPhone="isPhone" :buttonList="buttonList" @curBtn="changeIndex" :curBtn="curBtn"
			:title="$t('industryCustomers.name')">
		</hangar>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<el-row class="search_input" type="flex">
			<el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4"></el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="zui-w-100 zui-h-100">
				<el-row :gutter="0" type="flex" class="contentItem">
					<el-input size="small" clearable class="input" v-model="search_input" :placeholder="$t('industryCustomers.mesg3')"></el-input>
					<el-button class="button" size="small" color="#DBB956" @click="search()">{{$t('industryCustomers.mesg4')}}</el-button>
				</el-row>
			</el-col>
		</el-row>
		<el-row class="contantBox" type="flex">
			<el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4"></el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="zui-w-100 zui-h-100">
				<el-row :gutter="0" type="flex" class="contentItem">
					<el-col :xs="5" :sm="2" :md="3" :lg="3" :xl="3" class="contentLeft" id="contentLeft">
						<ul>
							<li class="titleLi" @click="changeLi(index)" v-for="(item,index) in listData" :key="index"
								:class="[current==index?'current':'']">{{item.company}}</li>
							<li @click="LoadMore()" class="titleLi" v-if="isMoreLoad">{{$t('industryCustomers.moreText[0]')}}</li>
							<!-- <li class="titleLi" v-else>{{$t('industryCustomers.moreText[1]')}}</li> -->
							
						</ul>
					</el-col>
					<el-col :xs="19" :sm="22" :md="21" :lg="21" :xl="21" class="contentRight">

						<div class="msg-title">
							<ul>
								<li></li>
								<li>
									<span :class="[currentLi == index ? 'currentLi' : '']"
										@click.stop="changeTitle(index)" v-for="(item, index) in title"
										:key="index">{{ item.name }}</span>
								</li>
								<li></li>
							</ul>
						</div>
						<div class="msg-body" v-if="isShowReport">
							<Detail v-if="currentLi == 0 && !isShowForm" :detail="detail"></Detail>
							<List v-if="currentLi == 1 && !isShowForm" :detail="detail" @addForm="changeForm"></List>
							<Form v-if="isShowForm" :detail="detail" @submit="submitEnd"></Form>
						</div>
						<div class="msg-body" v-else>
							<div>{{$t('industryCustomers.mesg2')}}</div>
						</div>
					</el-col>
				</el-row>
			</el-col>
			<!-- <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4"></el-col> -->
		</el-row>
	</div>
</template>

<script>
	// 行业客户
	import hangar from '../components/hangarCom';
	import Detail from '../components/customer/detail.vue';
	import Form from '../components/customer/form.vue';
	import List from '../components/customer/list.vue';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	export default {
		components: {
			hangar,
			Detail,
			List,
			Form
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin','language']),
			isShowReport() {
				if (this.userInfo.userAuthority) {
					let isShow = this.userInfo.userAuthority.indexOf("customer")
					return isShow != -1 ? true : false;
				} else {
					return false;
				}

			}
		},

		data() {
			return {
				isPhone: false,
				title: [{
					name: this.$t('industryCustomers.title1')
				}, {
					name: this.$t('industryCustomers.title2')
				}],
				currentLi: 0,
				buttonList: [],
				curBtn: 0,
				listData: [],
				current: 0,
				detail: {},
				isShowForm: false,
				pageN: 2,
				pageS: 20,
				total: '',
				search_input: '',
				ismore:true,
			};
		},
		watch: {
			language() {
				this.title = [{
					name: this.$t('industryCustomers.title1')
				}, {
					name: this.$t('industryCustomers.title2')
				}]
			}
		},

		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}
			if (!this.isLogin) {
				this.$message({
					message: this.$t('industryCustomers.mesg'),
					showClose: true,
					type: 'error',
					customClass: 'hahaha',
					duration: 2000,
					offset: 20,
					onClose: () => {
						this.$router.push({
							path: '/login'
						});
					}
				});
				return;
			}
			
			//获取数据
			let url = this.$apiUrl + '/front/queryCustomerList?pageNo=1&pageSize=20'
			this.axios({
				method: 'GET',
				// url: this.$apiUrl + '/aircustomer/airCustomer/list',
				url: url,
				headers: {
					'X-Access-Token': this.token
				}
			}).then(res => {


				if (res.data.code == 200) {
					let data = res.data.result
					this.total = data.total
					this.listData = data.records;
					this.detail = this.listData[0]
					var _that = this;	
					document.onkeydown = function() { //实现回车登录
						var path= _that.$router.history.current.path;
						var key = window.event.keyCode;
						if (key == 13&& path=='/industryCustomers') {
							_that.search()
								
						}
					}
				} else {
					this.$message({
						message: res.data.message,
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.data.message);
				}
			}).catch(err => {
				console.log(err);
				this.$message({
					message: this.$t('industryCustomers.token'),
					showClose: true,
					type: 'error',
					customClass: 'hahaha',
					duration: 2000,
					offset: 20,
					onClose: () => {
						this.logOut();
						this.$router.push({
							name: 'login'
						});
					}
				});
			});
		},

		mounted: function() {

		},
		updated() {
		// var chatdom =document.querySelector("#contentLeft")
		// 	chatdom.scrollTop = chatdom.scrollHeight
		},
		methods: {
			...mapMutations(['logOut']),
			LoadMore() {
				// if(this.ismore){
					let url = this.$apiUrl + '/front/queryCustomerList?pageNo=' + this.pageN + '&pageSize=' + this.pageS +'&search='+ this.search_input

					//获取数据
					this.axios({
						method: 'GET',
						// url: this.$apiUrl + '/aircustomer/airCustomer/list',
						url: url,
						headers: {
							'X-Access-Token': this.token
						}
					}).then(res => {
						// console.log(res.data);
						if (res.data.code == 200) {
							let redata = res.data.result.records;

							if (this.pageN >= 2) {

								this.listData = this.listData.concat(redata)
							}
							console.log(this.total,this.pageN,this.pageS,1111111)
							if (this.total - this.pageN * this.pageS > 0) {
								this.isMoreLoad = true; //显示 加载更多 按钮
								
							} else {
								this.isMoreLoad = false; //不再显示 加载更多 按钮
								// this.ismore = false
							}
							// setTimeout(()=>{
								
							// 	this.isMoreLoad = true; //显示 加载更多 按钮
							// },5000)
							//分页++
							this.pageN++;
							console.log(this.pageN)
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.data.message);
						}


					})
				// }
			},
			changeForm() {
				this.isShowForm = !this.isShowForm
			},
			submitEnd() {
				this.currentLi = 1;
				this.changeForm();
			},
			changeIndex(e) {
				this.curBtn = e;
			},
			changeLi(e) {
				// console.log(e);
				this.current = e;
				this.currentLi = 0;

				this.detail = this.listData[e]
			},
			changeTitle(index = 0) {
				this.currentLi = index;
				this.isShowForm = false;
			},
			search(){
				
				//无值				
				if(this.search_input==""){
					// 清空数据操作
					this.pageN=2
					this.pageS = 20
					this.isMoreLoad = true
					//获取数据
					let url = this.$apiUrl + '/front/queryCustomerList?pageNo=1&pageSize=20'
					this.axios({
						method: 'GET',
						// url: this.$apiUrl + '/aircustomer/airCustomer/list',
						url: url,
						headers: {
							'X-Access-Token': this.token
						}
					}).then(res => {
						if (res.data.code == 200) {
							let data = res.data.result
							this.total = data.total
							this.listData = data.records;
							this.detail = this.listData[0]
							
							console.log(this.total,this.listData)
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.data.message);
						}
					}).catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('industryCustomers.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
					
				}else{ //有值
					this.pageN = 2
					this.pageS = 20
					this.isMoreLoad = true
					this.total = ''
					let url = this.$apiUrl + '/front/queryCustomerList?pageNo=1&pageSize=20&search='+ this.search_input
					this.axios({
						method: 'GET',
						// url: this.$apiUrl + '/aircustomer/airCustomer/list',
						url: url,
						headers: {
							'X-Access-Token': this.token
						}
					}).then(res => {
					
						if (res.data.code == 200) {
							let data = res.data.result
							this.total = data.total
							this.listData = data.records;
							this.detail = this.listData[0]
							
							// console.log(this.total,this.pageN,123456)
							// if (this.total - this.pageN * this.pageS > 0) {
							// 	this.isMoreLoad = true; //显示 加载更多 按钮
							// } else {
							// 	this.isMoreLoad = false; //不再显示 加载更多 按钮
							
							// }
							// setTimeout(()=>{
							// 	this.isMoreLoad = true; //显示 加载更多 按钮
							// },5000)
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.data.message);
						}
					}).catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('industryCustomers.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
					
					console.log(this.search_input)
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.search_input{
		.contentItem {

			padding: 20px;
			display: flex;
			
			.input{
				// height: 50px;
				max-width:250px;
				display: flex;
				align-items: center;
				// outline: #DBB956;
			}
			/deep/ .el-input__inner{
				// height: 50px;
				border-color: #ccc;
			}
			/deep/ .el-input__inner:hover{
				// height: 50px;
				border-color: #DBB956;
				
			}
			.button{
				// width: 70px;
				margin-left: 20px;
				border: none;
				color: black;
				background-color: #DBB956;
			}
			.button:hover{
				color: white;
				background-color: #DBB956;
			}
		}
	}
	.industry_page {
		padding: 0;
		margin: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		.contantBox {
			flex: 1;
			min-height: 600px;

			.contentItem {
				padding: 20px;
				width: 100%;
				height: 100%;
				box-sizing: border-box;

				.contentLeft {
					background-color: #F1F1F1;
					border-right: 1px solid #E3E3E3;
					box-shadow: 7px 0px 7px -7px rgba(14, 10, 14, 0.8);
					height: 100vh;
					overflow-y: scroll;
					max-height: 100%;

					&::-webkit-scrollbar {
						width: 0px;
					}

					ul {
						width: 100%;
						height: 100%;
						list-style: none;

						li {
							box-sizing: border-box;

							border-bottom: 1px solid #D6CFCA;
							width: 100%;
							min-height: 50px;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							font-size: 16px;

							&.current {
								background-color: white;
								color: #DBB96E;
							}
						}
					}
				}

				.contentRight {
					background-color: #FFFFFF;
					padding: 20px 20px 0;
					margin-left: 20px;

					.msg-title {
						min-height: 50px;
						padding-bottom: 20px;
						// background-color: black;
						box-sizing: border-box;

						ul {
							display: flex;
							justify-content: space-between;
							align-items: center;
							list-style: none;
							margin: 0;
							padding: 0;
							height: 100%;
							width: 100%;
							border-bottom: 1px solid rgba($color: #DBB956, $alpha: 1);

							li {
								margin: 0;
								padding: 0;
								flex: 1;
								height: 100%;
								display: flex;
								align-items: center;

								span {
									padding: 6px 15px;
									background-color: #000000;
									color: white;
									// width: 48%;
									height: 100%;
									cursor: pointer;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 12px;

									&.currentLi {
										background-color: #DBB956;
										color: #000000;
									}
								}

							}
						}
					}

					.msg-body {
						flex: 1;
						// overflow-y: scroll;
						// width: 100%;
					}
				}
			}
		}
	}

	@media only screen and (max-width: 960px) {
		.contentItem {
			padding: 5px !important;
		}

		.right .contentRight-r li .li_l>>> {
			width: 50% !important;
		}

		.msg-title {
			ul {
				li {
					justify-content: center;

					span {
						width: 33% !important;
					}

					&:first-child {
						display: none !important;
					}

					&:last-child {
						display: none !important;
					}
				}
			}
		}

		/deep/ .contentLeft {

			ul {
				li.titleLi {
					font-size: 12px !important;
				}
			}
		}

		.contentRight {
			padding: 10px 0px 10px 0 !important;
			margin-left: 5px !important
		}

		/deep/ .contentRight-l {
			width: 45% !important;

			li {
				span {
					font-size: 12px !important;
				}
			}
		}

		.contentRight-r {
			li {
				width: 100% !important;

				span {
					font-size: 12px !important;

				}
			}
		}

	}

	.contentRight-r>li {
		width: 100% !important;
	}
</style>
